import React from 'react';
import FileSelect from '../file-select'
import LrcFileSelect from '../lrc-file-select'

export default class KaraokeQuickPlay extends React.Component {
  render() {
    const { canContinue, handleSaveAudioBuffer, handleSaveMetaData, handleContinue, handleSetSongName, handleSetArtistName, handleSetVideoId, handleSetLyrics } = this.props

    return (
      <div>
        <div className="fileSelect">
          <h1>{"Ready to sing?"}</h1>
          <h2>{"Import song"}</h2>
          <FileSelect
            handleSaveAudioBuffer={handleSaveAudioBuffer}
            handleSaveMetaData={handleSaveMetaData}
          />
        </div>
        <div className="fileSelect">
          <h2>{"Load LRC file"}</h2>
          <LrcFileSelect
            handleSetSongName={handleSetSongName}
            handleSetArtistName={handleSetArtistName}
            handleSetVideoId={handleSetVideoId}
            handleSetLyrics={handleSetLyrics}
          />
          <button disabled={!canContinue} onClick={handleContinue}>Contine > Play</button>
        </div>
      </div>
    );
  }
}
