import React from 'react'
import LrcPlayer from '../lrc-player'
import styles from './style.module.scss'

export default class LrcPlayMode extends React.Component {
  render() {
    return (
      <div className={styles.lrcPlayMode}>
        <div className={styles.lrcPlayerContainer}>
          <iframe
            className={styles.backgroundVideo}
            title="backgroundVideo"
            src={`https://www.youtube.com/embed/${this.props.videoId}?rel=0&amp;autoplay=1&amp;mute=1&amp;controls=0`}
            frameBorder="0"
            allowFullScreen
          />
          <div className={styles.lrcPlayer}>
            <LrcPlayer
              lyrics={this.props.lyrics}
              currentIndex={this.props.currentIndex}
              playAudio={true}
              handleSetCurrentIndex={this.props.handleSetCurrentIndex}
              handlePlayAudio={this.props.handlePlayAudio}
            />
          </div>
        </div>
        <button onClick={this.props.handleEnableFilter}>{`Karaoke filter: ${this.props.isKaraokeFilterEnabled ? 'On':  'Off'}`}</button>
      </div>
    )
  }
}
