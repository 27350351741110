import React from 'react';
import styles from './style.module.css'
import { createLrc } from '../../modules/lrcUtilties';

// Function to download data to a file
function download(data, filename, type) {
  var file = new Blob([data], {type: type});
  if (window.navigator.msSaveOrOpenBlob) // IE10+
      window.navigator.msSaveOrOpenBlob(file, filename+type);
  else { // Others
      var a = document.createElement("a"),
              url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename+type;
      document.body.appendChild(a);
      a.click();
      setTimeout(function() {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);  
      }, 0); 
  }
}

class LyricsDisplayExport extends React.Component {
  constructor(props) {
    super(props)
    const { madeBy, songLength, lyrics, artistName, songName, videoId } = this.props

    this.data = createLrc({
      artistName,
      album: 'unknown',
      songTextAuthor: artistName,
      length: songLength,
      madeBy,
      songName,
      videoId,
      lyrics
    })
  }

  render() {
    return (
      <div>
        <button onClick={() => download(this.data, this.props.songName, '.lrc')}>Save file</button>
        <div className={styles.lrcData}>
          {this.data.split("\n").map((line, index) => <div key={`line${index}`}>{line}</div>)}
        </div>
      </div>
    )
  }
}

export default LyricsDisplayExport
