import React from 'react'
import LrcPlayer from '../lrc-player'
import TextVerticalScroller from '../../toolkit/text-vertical-scroller'
import styles from './style.module.css'
import {
  parseLrc,
  addDuration
} from '../../modules/lrcUtilties'

function playSong (jsonObj) {
  // If the json data from the server is an lrc file
  if (jsonObj.lrc) {
    const lrcData = parseLrc(jsonObj.lrc)
    const lyrics = addDuration(lrcData.lrcWords)
    this.props.handleSetLyrics(lyrics)
  }

  this.setState({
    videoStartSecond: jsonObj.videoStartSecond || 0,
    songDuration: jsonObj.duration_ms / 1000,
    plainText: jsonObj.plainText // plain text will be null if we found lrc
  })

  this.props.handleSetVideoId(jsonObj.videoId)
}

export default class LrcFreePlayMode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: null,
      videoStartSecond: 0,
      songDuration: null,
      plainText: null,
      clientConnection: false
    }

    this.ws = new WebSocket(`wss://${process.env.REACT_APP_SERVER_URL}/client`);
    this.ws.addEventListener('open', () => {
      // Web Socket is connected, send data using send()
      this.ws.send(JSON.stringify({
        event: "init_serverclient",
        id: 'serverclient_KATWIN'
      }))
    });

    this.ws.addEventListener("message", (evt) => {
      let jsonObj = {
        event: ''
      }
      try {
        jsonObj = JSON.parse(evt.data)
        console.log('socket msg', jsonObj)
      } catch (err) {
        jsonObj.event = ''
        console.error('socket message error', err)
      }

      switch(jsonObj.event) {
        case 'init_serverclient_complete': {
          this.setState({id: jsonObj.id })
          this.ws.send(JSON.stringify({
            event: "next:song",
            id: 'serverclient_KATWIN'
          }))
          break
        }

        case 'enqueued:song':
          // If a song is playing, ignore. Otherwise, play the song
          if (!this.props.lyrics.length) {
            playSong.call(this, jsonObj)
          }
          break


        case 'play:song': {
          playSong.call(this, jsonObj)
          break
        }

        case 'remote_connected': {
          this.setState({ clientConnection: true })
          break
        }

        default:
          break
      }
    })
  }

  render() {
    if (!this.state.id) {
    return <div>
        <h1>{'In development -- In the meantime, create more LRC files'}</h1>
        <a href='/create'>{'Create LRC'}</a>
      </div>
    }

    if (!this.state.clientConnection) {
      return <div>{`Join session with id ${this.state.id}`}</div> 
    }
  
    if (!this.props.videoId) {
      return <div>{"no video"}</div> 
    }

    return (
      <div className={styles.lrcPlayMode}>
        <iframe
          className={styles.backgroundVideo}
          title="backgroundVideo"
          src={`https://www.youtube.com/embed/${this.props.videoId}?rel=0&amp;autoplay=1&amp;controls=0${this.state.videoStartSecond && `&amp;start=${this.state.videoStartSecond}`}`}
          frameBorder="0"
          allowFullScreen
        />
        {this.props.lyrics.length && <div className={styles.lrcPlayer}>
          <LrcPlayer
            autoPlay={true}
            videoId={this.props.videoId}
            lyrics={this.props.lyrics}
            playAudio={false}
            currentIndex={this.props.currentIndex}
            handleSetCurrentIndex={this.props.handleSetCurrentIndex}
            handlePlayAudio={this.props.handlePlayAudio}
          />
        </div>}
        {this.state.plainText && <TextVerticalScroller
          text={this.state.plainText}
          totalDuration={this.state.songDuration}
        />}
      </div>
    )
  }
}
