import React from 'react';
import styles from './style.module.css'

export default class TextVerticalScroller extends React.Component {
  render() {
    const textLines = this.props.text.split("\n").map((word, index) => <div key={'word' + index}>{word}</div>)
    return (
      <div className={styles.marquee}>
        <div
          style={{ animationDuration: this.props.totalDuration + 's' }}>
          {textLines}
        </div>
      </div>
    )
  }
}
