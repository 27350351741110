import React from 'react';
import Slider from 'rc-slider';
const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

function compareFloat (f1, f2) {
  return Math.abs(f1 - f2) < 0.00001
}

function verseZoomTime (verse) {
  return verse.map((lyric) => lyric.time * 100)
}

class VerseScrubber extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      rangeTimes: verseZoomTime(props.verse)
    }
  }

  render() {
    const { verse, handleSetTimes } = this.props
    const rangeOffset = 10
    const times = verseZoomTime(verse)
    const min = Math.round(times[0] - rangeOffset)
    return (
      <Range
        value={this.state.rangeTimes}
        min={min > 0 ? min : 0}
        max={Math.round(times[times.length - 1] + rangeOffset)}
        tipFormatter={value => {
          const index = this.state.rangeTimes.findIndex(e => e === value)
          const nextValue = this.state.rangeTimes[index + 1]
          return  `"${verse[index] ? verse[index].word : ''}" | time: ${value * 0.01} | duration: ${(nextValue - value) * 0.01}`
        }}
        onChange={(results) => this.setState({ rangeTimes: results })}
        onAfterChange={() => {
          setTimeout(() => {
            for (let i = 0; i < times.length; ++i) {
              if (!compareFloat(times[i], this.state.rangeTimes[i])) {
                handleSetTimes({
                  index: i,
                  time: this.state.rangeTimes[i] * 0.01
                })
                break
              }
            }
          }, 1000)
        }} />
    )
  }
}

export default VerseScrubber
