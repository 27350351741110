import React from 'react';
import styles from './style.module.scss'

function isFileFormatAccepted(file, acceptedFormats) {
  return file && acceptedFormats.some(form => file.name.endsWith(form))
}

function fileSelectHandler(e) {
  const { acceptedFormats } = this.props
  fileDragHover(e)
  const droppedFiles = e.target.files || e.dataTransfer.files
  const file = droppedFiles[0]
  if (!isFileFormatAccepted(file, acceptedFormats)) {
    this.setState({ error: `Error: File not accepted. Allowed types: ${acceptedFormats.join(', ')}` })
    return
  }

  try {
    this.fileReader.readAsArrayBuffer(file)
    this.setState({ fileName: file.name, error: '' })
  } catch (error) {
    console.error(error)
  }
}

function fileDragHover (e) {
  e.stopPropagation();
  e.preventDefault();
}

export default class SelectFile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fileName: '',
      error: ''
    }

    this.fileReader = new FileReader()
    this.fileReader.onload = props.handleOnLoad
  }

  render() {
    const { title, inputId, acceptedFormats } = this.props
    return (
      <div
        className={styles.fileDrag}
        onDragOver={e => fileDragHover.call(this, e)}
        onDragLeave={e => fileDragHover.call(this, e)}
        onDrop={e => fileSelectHandler.call(this, e)}>
        <div>{title}</div>
        <div>{`Accepts: ${acceptedFormats.join(', ')}`}</div>
        <img className={styles.uploadImg} src="/upload.png" alt="Upload file" />
        <div className={styles.fileSelectContainer}>
          <label htmlFor={inputId}>{this.state.fileName || 'Browse files...'}</label>
          <input id={inputId} className={styles.fileSelect} type="file" name="fileselect[]" accept={acceptedFormats.join(',')} onChange={(e) => fileSelectHandler.call(this, e)} />
          {this.state.error && <div className={styles.error}>{this.state.error}</div>}
        </div>
      </div>
    );
  }
}
