import React from 'react';
import KaraokeWords from '../karaoke-words'

export default class VerseView extends React.Component {
  render() {
    const { verse, currentIndex } = this.props
    const verseView = verse.map((wordInfo, i) => {
      return (
        <KaraokeWords
          key={'words_' + i}
          currentIndex={currentIndex}
          wordIndex={wordInfo.originalIndex}
          duration={wordInfo.duration}
          text={wordInfo.word} />
      )
    })

    return (
      <div>
        {verseView}
      </div>
    )
  }
}
