import React from 'react';
import './App.scss';
import FetchLyrics from './components/fetch-lyrics'
import FileSelect from './components/file-select'
import KaraokeQuickPlay from './components/karaoke-quickplay'
import LrcFileSelect from './components/lrc-file-select'
import LyricsDisplay from './components/lyric-display'
import LyricsDisplayExport from './components/lyric-display-export'
import LyricsEdit from './components/lyric-edit'
import LrcPlayMode from './components/lrc-play-mode'
import LrcFreePlayMode from './components/lrc-free-play-mode'
import Header from './toolkit/header'
import Footer from './toolkit/footer'
import {
  playAudioData,
  currentTime,
  songLength,
  AudioState,
  setAudioState
} from 'karaoke-audio'
import {
  addDuration,
  cleanLyrics,
  formatLrc,
  createLrc,
  secondToMinuteStr
} from './modules/lrcUtilties'
import {
  createWebSocket
} from './modules/server-communicator'

const Steps = {
  LoadMusic: 'lrc_music',
  LoadLrcFile: 'lrc_load',
  Record: 'lrc_record',
  EditLrc: 'lrc_edit',
  Export: 'lrc_export',
  Play: 'lrc_play',
  VideoId: 'lrc_video',
  FreePlay: 'free_play',
  LoadQuickPlay: 'play_load'
}

function playAudio(audioOptions = {}) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await playAudioData(this.state.audioBuffer, audioOptions)
      if (audioOptions.lyrics) {
        setTimeout(() => toggleKaraokeOnWord.call(this, 0, audioOptions.lyrics), audioOptions.lyrics[0].time * 1000)
      }
      resolve(result)
    } catch (err) {
      reject(err)
    }
  })
}

function createRecord (timeOffset = 0) {
  if (this.state.currentIndex >= this.state.lyrics.length) return
  // round to the second decimal place
  this.state.lyrics[this.state.currentIndex].time = timeOffset + (Math.round(currentTime() * 100.0) / 100.0);
  this.setState({
    lyrics: this.state.lyrics,
    currentIndex: this.state.currentIndex + 1 < this.state.lyrics.length ? this.state.currentIndex + 1 : this.state.currentIndex
  })
}

function setLyricsFromWords (lyrics) {
  this.setState({
    rawLyrics: lyrics,
    lyrics: cleanLyrics(lyrics)
  })
}

function toggleKaraokeOnWord(index, lyrics) {
  if (index === lyrics.length) return
  const curLyric = lyrics[index]
  setAudioState(AudioState.KaraokeMode, this.state.isKaraokeFilterEnabled && !curLyric.newVerse)
  setTimeout(() => toggleKaraokeOnWord.call(this, index + 1, lyrics), curLyric.duration * 1000)
}

function showQuickPlayOnSongEnd () {
  const timeout = songLength() * 1000
  setTimeout(() => setStep.call(this, 'play', Steps.LoadQuickPlay), timeout)
}

async function uploadLrc () {
  try {
    const fname = `${this.state.artistName}_${this.state.songName}`.replace(/[^A-Z0-9]/ig, "_")
    const lrc = createLrc({
      artistName: this.state.artistName,
      album: 'unknown',
      songTextAuthor: this.state.artistName,
      length: secondToMinuteStr(songLength()),
      madeBy: 'lrcmaker.com',
      songName: this.state.songName,
      videoId: this.state.videoId,
      lyrics: this.state.lyrics
    })

    await fetch('https://lrcmaker.com/scripts/upload.php', {
      method: 'post',
      body: JSON.stringify({
        fname,
        lrc
      })
    })
  } catch (err) {}
}

function setAudioBuffer (buffer) {
  this.setState({ audioBuffer: buffer })
}

function setStep (mode, step) {
  this.setState({ step })
  this.props.history.push(`/${mode}/${step}`)
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}

function startStep () {
  const pathname = (new URL(window.location.href)).pathname
  let step = Steps.Home
  if (pathname.includes('create')) step = Steps.LoadMusic
  else if (pathname.includes('freeplay')) step = Steps.FreePlay
  else if (pathname.includes('play')) step = Steps.LoadQuickPlay
  return step
}

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      step: startStep(),
      rawLyrics: '',
      lyrics: [],
      songName: '',
      artistName: '',
      videoId: '',
      album: '',
      songYear: '',
      currentIndex: 0,
      audioBuffer: null,
      isKaraokeFilterEnabled: false
    }

    this.ws = createWebSocket()
  }

  render() {
    let stepDisplay = null
    switch (this.state.step) {
      case Steps.LoadQuickPlay: {
        const saveMetaData = (metaData) => {
          this.setState({
            songName: metaData.title,
            artistName: metaData.artist,
            album: metaData.album,
            songYear: metaData.year,
          })
        }

        const next = async () => {
          await playAudio.call(this, { start: 0, duration: 0 })
          setAudioState(AudioState.Running, false)
          await uploadLrc.call(this)
          this.setState({ currentIndex: 0 })
          setStep.call(this, 'play', Steps.Play)
        }

        stepDisplay = (
          <KaraokeQuickPlay
            handleSaveAudioBuffer={audioData => setAudioBuffer.call(this, audioData)}
            handleSaveMetaData={saveMetaData}
            handleSetSongName={e => this.setState({ songName: e })}
            handleSetArtistName={e => this.setState({ artistName: e })}
            handleSetVideoId={e => this.setState({ videoId: e })}
            handleSetLyrics={e => this.setState({ lyrics: e })}
            canContinue={this.state.audioBuffer && this.state.lyrics.length}
            handleContinue={next}
          />
        )
        break
      }

      case Steps.LoadLrcFile:
        stepDisplay = (
          <div className="fileSelect">
            <h1>{"Step 2"}</h1>
            <h2>{"Add lyrics"}</h2>
            <FetchLyrics
              lyrics={this.state.rawLyrics}
              songName={this.state.songName}
              artistName={this.state.artistName}
              ws={this.ws}
              handleSetSongName={e => this.setState({ songName: e })}
              handleSetAlbum={e => this.setState({ album: e })}
              handleSetArtistName={e => this.setState({ artistName: e })}
              handleSetVideoId={e => this.setState({ videoId: e })}
              handleNextStep={res => {
                setLyricsFromWords.call(this, res)
                this.setState({ currentIndex: 1 })
                setStep.call(this, 'create', Steps.Record)
              }}
            />
            <h1>{"- Or -"}</h1>
            <h2>{"Load LRC file"}</h2>
            <LrcFileSelect
              handleSetSongName={e => this.setState({ songName: e })}
              handleSetArtistName={e => this.setState({ artistName: e })}
              handleSetVideoId={e => this.setState({ videoId: e })}
              handleSetLyrics={e => this.setState({ lyrics: e })}
            />
            <button disabled={!this.state.lyrics.length} onClick={() => {
              setStep.call(this, 'create', Steps.EditLrc)
            }}>Contine > Modify</button>
          </div>
        )
        break

      default:
      case Steps.LoadMusic:
        stepDisplay = (
          <div className="fileSelect">
            <h1>{"Import song"}</h1>
            <FileSelect
                handleSaveAudioBuffer={audioData => setAudioBuffer.call(this, audioData)}
                handleSaveMetaData={metaData =>
                  this.setState({
                    songName: metaData.title,
                    artistName: metaData.artist,
                    album: metaData.album,
                    songYear: metaData.year,
                  })
                }
            />
            <button disabled={!this.state.audioBuffer} onClick={async (e) => {
              await playAudio.call(this, { start: 0, duration: 0 })
              setAudioState(AudioState.Start, false)
              setStep.call(this, 'create', Steps.LoadLrcFile)
            }}>Contine > Lyrics</button>
          </div>
        )
        break

      case Steps.Record:
        stepDisplay = (
          <div className="step">
            <h1>{"Start recording"}</h1>
            <h2>How to use</h2>
            <iframe title="how to record lrc" width="560" height="315" src="https://www.youtube.com/embed/YSmCL_qvSvg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <div>{'Click in the gray box to start recording'}</div>
            <div>{'Use any key button to create a record'}</div>
            <LyricsDisplay
              handlePlaySong={() => playAudio.call(this)}
              handlePauseSong={() => setAudioState(AudioState.Running, false)}
              currentTime={currentTime}
              lyrics={formatLrc(this.state.lyrics)}
              songName={this.state.songName}
              songLength={secondToMinuteStr(songLength())}
              artistName={this.state.artistName}
              madeBy={'lrcmaker.com'}
              currentIndex={this.state.currentIndex}
              handleCreateRecord={() => createRecord.call(this)}
            />
            <button onClick={e => {
              setAudioState(AudioState.Running, false)
              this.setState({ currentIndex: 1 })
              setStep.call(this, 'create', Steps.EditLrc)
            }}>Contine > Fine tune</button>
          </div>
        )
        break

      case Steps.EditLrc:
        stepDisplay = (
          <div className="step fineTune">
            <h1>{"Fine tune"}</h1>
            <LyricsEdit
              handlePlayAudio={audioOptions => { return playAudio.call(this, audioOptions)} }
              handleSetAudioState={audioOptions => setAudioState(audioOptions.audioState, audioOptions.audioStateVale).catch(() => {})}
              handleSetLyrics={e => this.setState({ lyrics: e })}
              lyrics={addDuration(this.state.lyrics)}
              songLength={songLength()}
              currentIndex={this.state.currentIndex}
              handleSetCurrentIndex={index => this.setState({ currentIndex: index })}
              handleCreateRecord={timeOffset => createRecord.call(this, timeOffset)}
            />
            <button onClick={async (e) => {
              await uploadLrc.call(this)
              setStep.call(this, 'create', Steps.Export)
            }}>Contine > Export</button>
          </div>
        )
        break

      case Steps.Export:
        stepDisplay = (
          <div className="export">
            <h1>{"Export"}</h1>
            <button onClick={e => {
              this.setState({ currentIndex: 0 })
              setStep.call(this, 'create', Steps.Play)
            }}>Play Karaoke</button>
            <LyricsDisplayExport
              lyrics={this.state.lyrics}
              songName={this.state.songName}
              songLength={secondToMinuteStr(songLength())}
              artistName={this.state.artistName}
              videoId={this.state.videoId}
              madeBy={'lrcmaker.com'}
            />
          </div>
        )
        break

      case Steps.Play:
        stepDisplay = (
          <LrcPlayMode
            lyrics={this.state.lyrics}
            currentIndex={this.state.currentIndex}
            videoId={this.state.videoId}
            isKaraokeFilterEnabled={this.state.isKaraokeFilterEnabled}
            handleEnableFilter={() => this.setState({isKaraokeFilterEnabled: !this.state.isKaraokeFilterEnabled})}
            handleSetCurrentIndex={index => this.setState({ currentIndex: index })}
            handlePlayAudio={audioOptions => {
              showQuickPlayOnSongEnd.call(this)
              return playAudio.call(this, audioOptions) 
            }}
          />
        )
        break

      case Steps.FreePlay:
        stepDisplay = (
          <LrcFreePlayMode
            currentIndex={this.state.currentIndex}
            lyrics={this.state.lyrics}
            videoId={this.state.videoId}
            handleCreateLRC={() => setStep.call(this, 'freeplay', Steps.LoadMusic)}
            handlePlayAudio={audioOptions => { return playAudio.call(this, audioOptions) }}
            handleSetCurrentIndex={index => {
              // If it's the end of the song, clear the lyrics. Start 
              // checking for the next song
              if (index === this.state.lyrics.length - 1) {
                this.setState({ lyrics: [] })
              } else {
                this.setState({ currentIndex: index })
              }
            }}
            handleSetLyrics={e =>  this.setState({ lyrics: e })}
            handleSetVideoId={e => this.setState({ videoId: e })}
          />
        )
        break
    }

    return (
      <div className="App">
        <Header />
        <div className={"content"}>
          {stepDisplay}
        </div>
        <Footer />
      </div>
    );
  }
}


export default App;
