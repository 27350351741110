import React from 'react';

const Header = () => {
  return (
    <a className='logo' href='/'>
      <img src="/logo30.png" alt="LRC Maker" />
      <div>LRC Maker</div>
    </a>
  )
}

export default Header
