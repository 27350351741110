import React, { useRef } from 'react';
import styles from './style.module.css'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 200)

function lyricWord (word, i, currentIndex, scrollRef) {
  if (i === 0) return null
  let state = ''
  if (i < currentIndex) {
    state = styles.done
  } else if (i === currentIndex) {
    state = styles.current
  }

  const isVerseStart = word.includes('\n')
  return (
    <div
      key={`lyricMap_${i}`}
      ref={i === currentIndex ? scrollRef : null}
      className={`${state} ${isVerseStart ? styles.verse : styles.word}`}>
      {word}
      {isVerseStart ? '<-- end of previous word' : null}
    </div>
  )
}

function onFocus (props) {
  const { handlePlaySong, currentTime } = props
  if (currentTime() >= 0) return
  handlePlaySong()
}

const LyricsDisplay = (props) => {
  const scrollRef = useRef(null)
  const { madeBy, songLength, lyrics, currentIndex, handleCreateRecord, artistName, songName } = props
  const lyricWords = lyrics.map((word, i) => lyricWord(word, i, currentIndex, scrollRef))
  return (
    <div
      onFocus={() => onFocus.call(this, props)}
      className={styles.lyricMapContainer}
      tabIndex="0"
      onKeyDown={e => {
        e.preventDefault()
        e.stopPropagation()
        handleCreateRecord()
        scrollToRef(scrollRef)
      }}
    >
      <div>{`[ar: ${artistName}]`}</div>
      <div>{`[ti: ${songName}]`}</div>
      <div>{`[by: ${madeBy}]`}</div>
      <div>{`[length: ${songLength}]`}</div>
      <div className={styles.lyricMap}>
        {lyricWords}
      </div>
    </div>
  )
}

export default LyricsDisplay
