import React from 'react';
import styles from './style.module.scss'
import {
  wsEventToJson
} from '../../modules/server-communicator'

function responseVideoId (jsonObj) {
  const youtubeInfo = jsonObj.lyrics.data.media.find(media => media.provider === 'youtube')
  return youtubeInfo && youtubeInfo.url.split('=')[1];
}

export default class FetchLyrics extends React.Component {
  constructor(props) {
    super(props)
    this.FETCH_ERROR_MSG = 'Hm. There was a error when trying to find the lyrics. Try a different song, wait a bit, or manually look up.'
    this.FETCH_WS_CLOSED = 'Hm. The web server is closed.'
    this.FETCH_WS_ERROR = 'Hm. The web server has an error.'
    this.TRY_AGAIN_TIMER = 3000
    this.fetchAttemptCount = 0
    this.state = {
      error: null,
      lyricWords: props.lyrics,
      canSearch: this.props.ws.readyState === 1,
      isSearching: false,
      fetchError: '',
      fetchTimerActive: false
    }

    this.props.ws.addEventListener('open', () => {
      this.setState({ canSearch: true })
    });

    this.props.ws.addEventListener('error', err => {
      console.log('ws error')
      this.setState({
        isSearching: false,
        canSearch: false,
        fetchError: this.FETCH_WS_ERROR
      })
    });

    this.props.ws.addEventListener('close', () => {
      console.log('ws close')
      this.setState({
        isSearching: false,
        canSearch: false
      })
    });

    this.props.ws.addEventListener("message", (evt) => {
      const jsonObj = wsEventToJson(evt)

      switch (jsonObj.event) {
        case 'lyrics:fetch_complete': {
          this.fetchAttemptCount = this.fetchAttemptCount += 1
          startFetchTimer.call(this)
          this.props.handleSetAlbum(jsonObj.lyrics.data.album ? jsonObj.lyrics.data.album.name : 'unknown')
          const videoId = responseVideoId(jsonObj)
          videoId && this.props.handleSetVideoId(videoId)
          this.setState({
            lyricWords: jsonObj.lyrics.data.lyrics,
            fetchError: ''
          })
          break
        }

        case 'lyrics:fetch_error': {
          this.fetchAttemptCount += 1
          startFetchTimer.call(this)
          this.setState({
            fetchError: this.FETCH_ERROR_MSG,
            fetchTimerActive: true
          })
          break
        }

        default: break;
      }
    })
  }

  render() {
    const { album, songName, artistName, handleNextStep, handleSetSongName, handleSetArtistName, handleSetAlbum } = this.props
    return (
      <div className={styles.fetchLyrics}>
        <input type="text" value={songName} placeholder={"Song"} onChange={e => handleSetSongName(e.target.value)} />
        <input type="text" value={artistName} placeholder={"Artist"} onChange={e => handleSetArtistName(e.target.value)} />
        <input type="text" value={album} placeholder={"Album (Optional)"} onChange={e => handleSetAlbum(e.target.value)} />
        {this.state.fetchError && <div className={styles.error}>{this.state.fetchError}</div>}
        {this.state.isSearching && <div>{"Searching..."}</div>}
        <button className={styles.search} disabled={!this.state.canSearch || this.state.fetchTimerActive} onClick={() => fetchLyrics.call(this)}>Search</button>

        <hr />
        <div className={styles.error}>{this.state.error}</div>
        <div>{'If search fails, manually enter the lyrics'}</div>
        <div>
          {'Remove any non-lyrics lines. (E.g. [Verse 1])'}
          <textarea
            placeholder={'Insert song lyrics'}
            className={styles.lyricMap}
            value={this.state.lyricWords}
            onChange={e => this.setState({ lyricWords: e.target.value })}
          />
        </div>
        <button disabled={!this.state.lyricWords.trim() || !artistName.trim() || !songName.trim()} onClick={() => handleNextStep(this.state.lyricWords)}>Contine > Video</button>
      </div>
    )
  }
}

function fetchLyrics() {
  this.setState({
    canSearch: false,
    isSearching: true
  })
  this.props.ws && this.props.ws.send(JSON.stringify({
    event: "lyrics:fetch",
    song: {
      name: this.props.songName,
      artists: this.props.artistName
    }
  }))
}

// Avoid getting d-dos by starting a timer till user can try again
function startFetchTimer () {
  setTimeout(() => this.setState({ fetchTimerActive: false }), this.TRY_AGAIN_TIMER * this.fetchAttemptCount)
}
