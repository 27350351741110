function padZero(num, size) {
  if (size === void 0) { size = 2; }
  while (num.toString().split('.')[0].length < size)
    num = '0' + num;
  return num;
}

export function timestampStr(timestamp) {
  return padZero(Math.floor(timestamp / 60)) + ":" + padZero((timestamp % 60).toFixed(2));
}

export function timestampStrToSeconds (timestampStr) {
  const a = timestampStr.split(':'); // split it at the colons
  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
}

export function secondToMinuteStr(second) {
  return Math.floor(second / 60) + ":" + (second % 60).toFixed(2);
}

export function formatLrc(lyrics) {
  return lyrics.map(e => {
    const timestampString = timestampStr(e.time)
    if (e.newVerse) {
      return `\n[${timestampString}] `
    } else {
      return `<${timestampString}> ${e.word} `
    }
  })
}

export function createLrc(options = {}) {
  let verses = []
  let verseWords = []
  const stringLyrics = formatLrc(options.lyrics)
  for (const lyric of stringLyrics) {
    if (lyric.includes('\n')) {
      verses.push(verseWords)
      verseWords = []
    }

    verseWords.push(lyric)
  }

  // Add remaining words
  verses.push(verseWords)

  let lrc =
    `[ar:${options.artistName}]
[al:${options.album}]
[au:${options.songTextAuthor}]
[length:${options.length}]
[by:${options.madeBy}]
[ti:${options.songName}]
[vi:${options.videoId}]`
  return lrc + verses.map((verse) => `\n${verse.join('').replace('\n', '')}`).join('')
}

export function cleanLyrics(lyrics) {
  const words = lyrics.replace(/(\r\n|\n|\r)/gm, " \n").split(" ")
  let newLyrics = []
  newLyrics.push({
    word: '',
    time: 0,
    duration: 0,
    newVerse: true
  })

  for (let i = 0; i < words.length; ++i) {
    const word = words[i]
    if (word.includes('\n')) {
      newLyrics.push({
        word: '',
        time: 0,
        duration: 0,
        newVerse: true
      })
    }

    newLyrics.push({
      word: word.replace('\n', ''),
      time: 0,
      duration: 0,
      newVerse: false
    })
  }

  return newLyrics
}

export function addDuration(lyrics) {
  return lyrics.map((lyric, index) => {
    lyric.duration = index < lyrics.length - 1 ? lyrics[index + 1].time - lyrics[index].time : 1
    return lyric
  })
}

export function verses(lyrics, isNewVerseFirst = true) {
  let allVerses = []
  let currentVerse = []
  for (let i = 0; i < lyrics.length; ++i) {

    if (isNewVerseFirst && i > 0 && lyrics[i].newVerse) {
      allVerses.push(currentVerse)
      currentVerse = []
    }

    currentVerse.push({
      ...lyrics[i],
      ...{
        originalIndex: i
      }})

      if (!isNewVerseFirst && i > 0 && lyrics[i].newVerse) {
        allVerses.push(currentVerse)
        currentVerse = []
      }
  }

  // Add the remaining song
  if (currentVerse.length) {
    allVerses.push(currentVerse)
  }

  return allVerses
}

export function findVerseIndex(verses, index) {
  return verses.findIndex((verse) => {
    for (const wordInfo of verse) {
      if (wordInfo.originalIndex === index) return true
    }

    return false
  })
}

export function findVerseByIndex(verses, index) {
  return verses.find((verse) => {
    for (const wordInfo of verse) {
      if (wordInfo.originalIndex === index) return true
    }

    return false
  })
}

export function parseLrcHeader(rawLrc) {
  let tags = {};
  const tagRegex = /\[([a-z]+):(.*)\].*/;
  const rawLrcLines = rawLrc.split(/[\r\n]/);
  let iLrcLine = 0
  // handle tags first
  for (; iLrcLine < rawLrcLines.length; ++iLrcLine) {
    const tag = tagRegex.exec(rawLrcLines[iLrcLine]);
    if (tag && tag[0]) {
      tags[tag[1]] = tag[2];
    } else {
      break
    }
  }

  return { tags, endOnLine: iLrcLine }
}

export function parseLrc(rawLrc) {
  const headers = parseLrcHeader(rawLrc)
  const rawLrcLines = rawLrc.split(/[\r\n]/);
  let iLrcLine = headers.endOnLine

  const RegexResultIndex = {
    Original: 0,
    Key: 1,
    Value: 2
  }


  // eslint-disable-next-line
  const verseRegex = /(\[[0-9.:\[\]]*\])+(.*)/;
  // eslint-disable-next-line
  const verseTimeRegex = /\[(\d{2,})\:(\d{2})(?:\.(\d{2,3}))?\]/;
  // eslint-disable-next-line
  const wordTimeRegex = /\<(\d{2,})\:(\d{2})(?:\.(\d{2,3}))?\>/;
  let lrcWords = []

  const time = (regex, target) => {
    const timeStr = regex.exec(target)
    if (!timeStr) return null
    return (parseInt(timeStr[1]) * 60 * 1000 + parseInt(timeStr[2]) * 1000 + (parseInt(timeStr[3]) * 10 || 0)) * 0.001
  }

  for (; iLrcLine < rawLrcLines.length; ++iLrcLine) {
    // handle lrc
    const lrcVerse = verseRegex.exec(rawLrcLines[iLrcLine]);
    if (lrcVerse && lrcVerse[RegexResultIndex.Original]) {
      const verseTimes = time(verseTimeRegex, lrcVerse[RegexResultIndex.Key])
      if (verseTimes === null) {
        continue
      }

      lrcWords.push({
        newVerse: true,
        word: ' ',
        time: verseTimes
      })

      const words = lrcVerse[RegexResultIndex.Value].split(' ')
      for (let iWord = 0; iWord < words.length; ++iWord) {
        const wordTime = time(wordTimeRegex, words[iWord])
        if (!wordTime) {
          continue
        }

        lrcWords.push({
          newVerse: false,
          word: words[iWord + 1],
          time: wordTime
        })

        ++iWord;
      }
    }
  }

  return {
    lrcWords,
    tags: headers.tags
  } 
}

export const PlaceholderLyrics = `This was a triumph
I'm making a note here, HUGE SUCCESS
It's hard to overstate my satisfaction
Aperture Science
We do what we must because we can
For the good of all of us
Except the ones who are dead
But there's no sense crying over every mistake
You just keep on trying till you run out of cake
And the science gets done
And you make a neat gun
For the people who are still alive
I'm not even angry
I'm being so sincere right now
Even though you broke my heart and killed me
And tore me to pieces
And threw every piece into a fire
As they burned it hurt because
I was so happy for you
Now these points of data make a beautiful line
And we're out of beta, we're releasing on time
So I'm GLaD I got burned, think of all the things we learned
For the people who are still alive
Go ahead and leave me
I think I prefer to stay inside
Maybe you'll find someone else to help you
Maybe Black Mesa
That was a joke, haha, FAT CHANCE
Anyway this cake is great
It's so delicious and moist
Look at me still talking when there's science to do
When I look out there it makes me GLaD I'm not you
I've experiments to run, there is research to be done
On the people who are still alive
And believe me I am still alive
I'm doing science and I'm still alive
I feel fantastic and I'm still alive
While you're dying I'll be still alive
And when you're dead I'll be still alive
Still alive.
STILL ALIVE!`

export const PlaceholderLyricsFormat = cleanLyrics(PlaceholderLyrics)

export const PlaceholderLyrics2 = [
  { word: " ", time: 0.0, newVerse: true },
  { word: "This", time: 0.20, newVerse: false },
  { word: "was", time: 0.50, newVerse: false },
  { word: "a", time: 0.70, newVerse: false },
  { word: "triumph", time: 0.90, newVerse: false },
  { word: " ", time: 2.90, newVerse: true },
  { word: "I'm", time: 4.17, newVerse: false },
  { word: "making", time: 4.47, newVerse: false },
  { word: "a", time: 4.76, newVerse: false },
  { word: "note", time: 5.02, newVerse: false },
  { word: "here,", time: 5.60, newVerse: false },
  { word: "HUGE", time: 5.82, newVerse: false },
  { word: "SUCCESS", time: 5.98, newVerse: false },
  { word: " ", time: 8.00, newVerse: true },
  { word: "It's", time: 9.00, newVerse: false },
  { word: "hard", time: 9.39, newVerse: false },
  { word: "to", time: 9.94, newVerse: false },
  { word: "overstate", time: 10.23, newVerse: false },
  { word: "my", time: 11.59, newVerse: false },
  { word: "satisfaction", time: 12.34, newVerse: false },
  { word: " ", time: 16.00, newVerse: true },
  { word: "Aperture", time: 16.43, newVerse: false },
  { word: "Science", time: 17.16, newVerse: false },
  { word: "", time: 20.03, newVerse: true },
  { word: "We", time: 20.15, newVerse: false }
]