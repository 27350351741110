import React from 'react';
import styles from './style.module.css'

const LyricWordDisplay = (props) => {
  const { timedString, currentIndex, offsetIndex, scrollRef } = props

  const lyricWords = timedString.map((word, i) => {
    let state = ''
    const lyricIndex = i + offsetIndex
    if (lyricIndex < currentIndex) {
      state = styles.done
    } else if (lyricIndex === currentIndex) {
      state = styles.current
    }

    return (
      <div
        className={state}
        key={`lyricMap_${lyricIndex}`}
        ref={i === currentIndex ? scrollRef : null} >
        {word.includes('\n') ? <br /> : null}
        {word}
      </div>
    )
  })

  return (
    <div
      className={styles.timedLyricsWrapper}>
      {lyricWords}
    </div>
  )
}

export default LyricWordDisplay
