import React from 'react';
import styles from './style.module.css'
import {
  verses,
  formatLrc
} from '../../modules/lrcUtilties'
import VerseEdit from '../verse-edit'

function iterateVerseCurrentIndex(maxIndex, index, verse) {
  if (index === maxIndex) return
  this.props.handleSetCurrentIndex(verse[index].originalIndex)
  setTimeout(() => {
    iterateVerseCurrentIndex.call(this, maxIndex, index + 1, verse)
  }, verse[index].duration * 1000)
}

async function startIterateVerse(options = {}) {
  const { verse, startIndex } = options
  try {
    await this.props.handlePlayAudio(options)
    iterateVerseCurrentIndex.call(this, verse.length, startIndex, verse)
  } catch (err) {
    console.error(err)
  }
}

class LyricsEdit extends React.Component {
  render() {
    const { handleSetLyrics, songLength, handlePlayAudio, handleSetCurrentIndex, handleCreateRecord } = this.props
    const songVerses = verses(this.props.lyrics, false)
    const lyricWords = songVerses.map((verse, index) => {
      const nextVerseStartTime = index < songVerses.length - 1 ? (songVerses[index + 1][0]).time : songLength
      return <VerseEdit
        key={'verse_' + index}
        lyrics={this.props.lyrics}
        verse={verse}
        index={index}
        nextVerseStartTime={nextVerseStartTime}
        timedString={formatLrc(verse)}
        currentIndex={this.props.currentIndex}
        songLength={songLength}
        handleCreateRecord={handleCreateRecord}
        handlePlayAudio={handlePlayAudio}
        handlePlayVerseAudio={this.props.handlePlayAudio}
        handleStartIterateVerse={options => { return startIterateVerse.call(this, options) }}
        handleSetLyrics={handleSetLyrics}
        handleSetCurrentIndex={handleSetCurrentIndex}
      />
    })

    return (
      <div className={styles.lyricMap}>{lyricWords}</div>
    )
  }
}

export default LyricsEdit
