import React from 'react';
import styles from './style.module.scss'
import LyricWordDisplay from '../../toolkit/lyric-word-display'
import TimeUpDown from '../../toolkit/time-up-down'
import VerseScrubber from '../../toolkit/verse-scrubber'
import VerseView from '../../toolkit/verse-view'

const SCRUBBER_ENABLED = false
const DEFAULT_DURATION = 1

const Mode = {
  preview: 'preview',
  record: 'record',
  fineTune: 'fine_tune'
}

function nextLyricNode(index, lyrics) {
  return index < lyrics.length - 1 ? lyrics[index + 1] : null
}

function setNewLyrics (originalIndex, time) {
  let newLyrics = this.props.lyrics
  const nextNode = nextLyricNode(originalIndex, newLyrics)
  newLyrics[originalIndex].time = time
  newLyrics[originalIndex].duration = nextNode ? nextNode.time - newLyrics[originalIndex].time : DEFAULT_DURATION
  this.props.handleSetLyrics(newLyrics)
  this.props.handleSetCurrentIndex(originalIndex)

  this.props.handlePlayAudio({
    start: newLyrics[originalIndex].time,
    duration: newLyrics[originalIndex].duration
  })
}

function playVerseAudio () {
  const { verse, handlePlayVerseAudio } = this.props
  const verseFirstNode = verse[0]
  const verseLastNode = verse[verse.length - 1]
  handlePlayVerseAudio({
    start: verseFirstNode.time,
    duration: (verseLastNode.time + verseLastNode.duration) - verseFirstNode.time,
    verse
  })
}

function startIterateVerse (nodeIndex) {
  const { verse, handleStartIterateVerse } = this.props
  const verseLastNode = verse[verse.length - 1]
  handleStartIterateVerse({
    startIndex: nodeIndex,
    start: verse[nodeIndex].time,
    duration: (verseLastNode.time + verseLastNode.duration) - verse[nodeIndex].time,
    verse
  })
}

export default class VerseEdit extends React.Component {
  constructor(props) {
    super(props)
    this.recordIndex = null
    this.state = {
      mode: Mode.preview
    }
  }

  render() {
    return (
      <div
        className={styles.verseEdit}>
        <div className={styles.tabContainer}>
        <button className={`${styles.tab} ${this.state.mode === Mode.preview ? styles.tabActive : ''}`} onClick={() => this.setState({ mode: Mode.preview })}>Preview</button>
          <button className={`${styles.tab} ${this.state.mode === Mode.record ? styles.tabActive : ''}`} onClick={() => this.setState({ mode: Mode.record })}>Re-record</button>
          <button className={`${styles.tab} ${this.state.mode === Mode.fineTune ? styles.tabActive : ''}`} onClick={() => this.setState({ mode: Mode.fineTune })}>Fine tune</button>
        </div>
        <div className={styles.tabContentWrapper}>
          {this.state.mode === Mode.preview && previewTemplate.call(this)}
          {this.state.mode === Mode.fineTune && fineTuneTempate.call(this)}
          {this.state.mode === Mode.record && recordTemplate.call(this)}
        </div>
      </div>
    )
  }
}

function previewTemplate() {
  const { currentIndex, index, handleSetCurrentIndex, verse } = this.props

  return (
    <div className={styles.previewVerse}>
      Click the 'Play verse' button to see the karaoke text hightlighting
      <button
        onClick={() => {
          const nodeIndex = verse[0].originalIndex === 0 ? 1 : 0
          handleSetCurrentIndex(verse[nodeIndex].originalIndex)
          startIterateVerse.call(this, nodeIndex)
        }}>
        Play verse
      </button>
      <VerseView
        key={'verseview' + index}
        verse={verse}
        currentIndex={currentIndex} />
    </div>
  )
}

function recordTemplate () {
  const { timedString, handleCreateRecord, handleSetCurrentIndex, verse } = this.props
  return (
    <div
      className={styles.lyricMap}
      onClick={e => {
        this.recordIndex = verse[0].originalIndex + 1
        handleSetCurrentIndex(this.recordIndex)
        playVerseAudio.call(this)
      }}
      onKeyDown={e => {
        e.preventDefault()
        e.stopPropagation()
        if (this.recordIndex > verse[verse.length - 1].originalIndex) return
        handleSetCurrentIndex(this.recordIndex)
        handleCreateRecord(verse[0].time)
        ++this.recordIndex
      }}
      tabIndex="0">
      Click inside box to begin music. Press any key to record new time.
      <LyricWordDisplay
        offsetIndex={verse[0].originalIndex}
        timedString={timedString}
        currentIndex={this.recordIndex} />
    </div>
  )
}

function fineTuneTempate () {
  const { nextVerseStartTime, songLength, currentIndex, verse, index, lyrics, handleSetCurrentIndex, handlePlayAudio } = this.props
  const firstNode = verse[0].originalIndex === 0 ? verse[1] : verse[0]
  const shiftVerseStartIndex = firstNode.originalIndex
  const lastNode = verse[verse.length - 1]
  const maxTime = firstNode.time + (nextVerseStartTime - lastNode.time)

  return (
    <div className={styles.fineTune}>
      <h3>{'Shift full version'}</h3>
      {'Is the timing a little late or early? Keep word durations, but shift verse to a new spot.'}
      <TimeUpDown
        min={lyrics[shiftVerseStartIndex - 1].time}
        max={maxTime}
        value={lyrics[shiftVerseStartIndex].time}
        handleValueChange={seconds => {
          let newLyrics = lyrics
          for (let i = shiftVerseStartIndex, lastIndex = verse[verse.length - 1].originalIndex; i <= lastIndex; ++i) {
            newLyrics[i].time = seconds
            seconds += newLyrics[i].duration
          }

          this.props.handleSetLyrics(newLyrics)
        }}
      />

      <div className={styles.previewVerse}>
        <button
          onClick={() => {
            const nodeIndex = verse[0].originalIndex === 0 ? 1 : 0
            handleSetCurrentIndex(shiftVerseStartIndex)
            startIterateVerse.call(this, nodeIndex)
          }}>
          Play verse
      </button>
        <VerseView
          key={'verseview' + index}
          verse={verse}
          currentIndex={currentIndex} />
      </div>


      {SCRUBBER_ENABLED && <div className={styles.rangeContainer}>
        <canvas className={styles.visualizer} id={"visualizer_" + index} height="100"></canvas>
        <VerseScrubber
          verse={verse}
          handleSetTimes={(result) => {
            setNewLyrics.call(this, verse[result.index].originalIndex, result.time)
          }}
        />
      </div>}

      <h3>{'Specific time stamps'}</h3>
      Set specific times for words. Tip: Set the beginning of the verse at the end of the previous verse (the end of the previous word).
      {verse.map((verseNode, iVerseNode) => {
        const originalIndex = verseNode.originalIndex
        const min = originalIndex > 0 ? lyrics[originalIndex - 1].time : 0
        const max = originalIndex < lyrics.length - 1 ? lyrics[originalIndex + 1].time : songLength
        return (
          <div
            key={`lyricMap_${originalIndex}`}
            className={styles.fineTuneLyricWord}
          >
            <TimeUpDown
              min={min}
              max={max}
              value={verseNode.time}
              handleValueChange={seconds => setNewLyrics.call(this, verse[iVerseNode].originalIndex, seconds)}
            />
            <button
              className={styles.word}
              onClick={e => {
                handleSetCurrentIndex(originalIndex)
                handlePlayAudio({ start: verseNode.time, duration: verseNode.duration })
              }}
            >
              {!verseNode.newVerse && verseNode.word}
              {verseNode.newVerse && ((iVerseNode === verse.length - 1) ? 'End of previous word' : 'Verse beginning')}
            </button>
          </div>
        )
      })}
      {'The "End of previous word" ties to the karaoke animation.'}
    </div>
  )
}
