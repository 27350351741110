import React from 'react';
import styles from './style.module.scss'

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className='logo'>
        <img src="/logo30.png" alt="LRC Maker" />
        <div>LRC Maker</div>
      </div>
      <div className={styles.about}>
        <a href={'/articles/general/about'} target='_blank' rel='noopener noreferrer'>About</a>
      </div>
      <div className={styles.about}>
        <a href={'https://pollev.com/surveys/bo5B2w8sbXHInhpG0RR50/respond'} target='_blank' rel='noopener noreferrer'>Feedback</a>
      </div>
    </footer>
  )
}

export default Footer
