import React from 'react';
import jDataView from 'jdataview'
import SelectFiles from '../../toolkit/select-file'

const ACCEPTED_FORMATS = [
  '.wav',
  '.ogg',
  '.mp4',
  '.mp3',
  '.m4a'
]

function getMetaData(file) {
  const dv = new jDataView(file);
  try {
    // "TAG" starts at byte -128 from EOF.
    // See http://en.wikipedia.org/wiki/ID3
    if (dv.getString(3, dv.byteLength - 128) === 'TAG') {
      return {
        title: dv.getString(30, dv.tell()).trim(),
        artist: dv.getString(30, dv.tell()).trim(),
        album: dv.getString(30, dv.tell()).trim(),
        year: dv.getString(4, dv.tell()).trim(),
      }
    }
  } catch (e) {}

  return null
}

function onLoad (fileEvent) {
  const file = fileEvent.target.result
  const metaData = getMetaData(file)
  this.props.handleSaveAudioBuffer(file)

  if (metaData) {
    this.props.handleSaveMetaData({
      title: metaData.title.replace(/[^a-z\d\s]+/gi, ''),
      artist: metaData.artist.replace(/[^a-z\d\s]+/gi, ''),
      album: metaData.album.replace(/[^a-z\d\s]+/gi, ''),
      year: metaData.year.replace(/[^0-9]/ig, '')
    })
  }
}

class FileSelect extends React.Component {
  render() {
    return (
      <SelectFiles
        title={`Drag & drop your audio files here.`}
        inputId={'audioInput'}
        acceptedFormats={ACCEPTED_FORMATS}
        handleOnLoad={e => onLoad.call(this, e)}
      />
    );
  }
}

export default FileSelect
