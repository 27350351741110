import React from 'react';
import SelectFile from '../../toolkit/select-file'

import {
  parseLrc,
  addDuration
} from '../../modules/lrcUtilties'

const ACCEPTED_FORMATS = [
  '.lrc'
]

function onLoad (fileEvent) {
  const data = parseLrc(this.textDecoder.decode(fileEvent.target.result))
  this.props.handleSetSongName(data.tags.ti)
  this.props.handleSetArtistName(data.tags.ar)
  this.props.handleSetVideoId(data.tags.vi)
  this.props.handleSetLyrics(addDuration(data.lrcWords))
}

export default class LrcFileSelect extends React.Component {
  constructor(props) {
    super(props)
    this.textDecoder = new TextDecoder("utf-8")
  }

  render() {
    return (
      <div>
        <SelectFile
          title={'Drag & drop your .lrc file here'}
          inputId={'lrcInput'}
          acceptedFormats={ACCEPTED_FORMATS}
          handleOnLoad={e => onLoad.call(this, e)}
        />
      </div>
    );
  }
}
