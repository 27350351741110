import React from 'react';
import {
  verses,
  findVerseIndex
} from '../../modules/lrcUtilties'
import VerseView from '../../toolkit/verse-view'
import styles from './style.module.css'

function iterateFullCurrentIndex(index) {
  const { lyrics } = this.props
  if (index === lyrics.length) return
  this.props.handleSetCurrentIndex(index)
  setTimeout(() => {
    iterateFullCurrentIndex.call(this, index + 1)
  }, lyrics[index].duration * 1000)
}

async function startSong () {
  try {
    if (this.props.playAudio) {
      await this.props.handlePlayAudio({ lyrics: this.props.lyrics })
    }

    iterateFullCurrentIndex.call(this, 0)

  } catch (err) {
    console.error(err)
  }
}

export default class LrcPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentVerseIndex: 0
    }

    this.verses = verses(props.lyrics)
  }

  componentDidMount() {
    startSong.call(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.videoId !== this.props.videoId) {
      startSong.call(this)
    }
  }

  render() {
    const verseIndex = findVerseIndex(this.verses, this.props.currentIndex)
    const modIndex = verseIndex % 3
    if (modIndex === 0) {
      // eslint-disable-next-line
      this.state.currentVerseIndex = verseIndex
    }

    const verseViewArray = [
      (this.state.currentVerseIndex === verseIndex) ? this.state.currentVerseIndex : this.state.currentVerseIndex + 3,
      (this.state.currentVerseIndex + 1 >= verseIndex) ? this.state.currentVerseIndex + 1 : this.state.currentVerseIndex + 4,
      this.state.currentVerseIndex + 2
    ].map(index =>
      index < this.verses.length && <VerseView
        key={'verseview' + index}
        verse={this.verses[index]}
        currentIndex={this.props.currentIndex} />
    )

    return (
      <div className={styles.versesWrapper}>
        {verseViewArray}
      </div>
    )
  }
}
