import React from 'react';
import styles from './style.module.scss'
import Header from '../../toolkit/header'
import Footer from '../../toolkit/footer'

export default class Home extends React.Component {
  render() {

    return (
      <div className={styles.home}>
        <Header />
        <div className={`${styles.sectionWrapper} ${styles.hero}`}>
          <div className={styles.section}>
            <h1>Create a karaoke song</h1>
            <p>Create or modify LRC files using songs that you own</p>
            <a href='/create'>GO TO CREATOR</a>
          </div>
        </div>

        <div className={`${styles.sectionWrapper} ${styles.hero}`}>
          <div className={styles.sectionColumnContent}>
            <div className={styles.section}>
              <h1>Want to sing?</h1>
              <p>Play an audio and LRC file</p>
              <a href='/play'>OPEN KARAOKE PLAYER</a>
            </div>
            <div className={styles.imgContainer}>
              <img className={styles.createImg} alt={'start singing'} src={'./sing.jpg'} />
              <div className={styles.imgGradient} />
            </div>
          </div>
        </div>

        <div className={styles.sectionWrapper}>
          <div className={styles.section}>
            <h1>More karaoke resources</h1>
            <ul className={styles.articles}>
              <li>
                <h3>What is LRC?</h3>
                <p>LRC is a file format that synchronizes song lyrics with an audio file.</p>
                <a href={'https://en.wikipedia.org/wiki/LRC_(file_format)'} target='_blank' rel='noopener noreferrer'>LRC WIKIPEDIA</a>
              </li>
              <li>
                <h3>At-home karaoke setup</h3>
                <p>Recommendations from users on their at-home karaoke setups</p>
                <a href={'https://www.katherinewinter.com/articles/party/home_karaoke'} target='_blank' rel='noopener noreferrer'>AT-HOME KARAOKE SETUP</a>
              </li>
            </ul>
          </div>
        </div>
        <a className={styles.feedback} href='https://pollev.com/surveys/bo5B2w8sbXHInhpG0RR50/respond' target='_blank' rel='noopener noreferrer'>
          <div>Send constructive feedback</div>
          <img src="/feedback.png" alt="Send feedback" />
        </a>
        <Footer />
      </div>
    );
  }
}
