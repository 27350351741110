import React from 'react';

function formatSecond (value) {
  return parseFloat(value.toFixed(2))
}

export default class TimeUpDown extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      second: formatSecond(this.props.value),
      isChanging: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ second: formatSecond(this.props.value) })
    }
  }

  render() {
    return (
      <input
        value={this.state.second}
        type='number'
        step="0.01"
        onKeyUp={e => tryToSubmitValue.call(this)}
        onMouseUp={e => tryToSubmitValue.call(this)}
        onBlur={e => tryToSubmitValue.call(this)}
        onChange={e => {
          if (e.target.value >= this.props.max) return
          if (e.target.value <= this.props.min) return
          this.setState({ isChanging: true })
          this.setState({ second: parseFloat(e.target.value) })
        }}
    />
    )
  }
}

function tryToSubmitValue () {
  const { handleValueChange } = this.props
  this.state.isChanging && handleValueChange(this.state.second)
  this.setState({ isChanging: false })
}
