export const LRC_URL = `http://${process.env.REACT_APP_SERVER_HOST}/karaoke`

export function createWebSocket() {
  return new WebSocket(`wss://${process.env.REACT_APP_SERVER_HOST}/client`);
}

export function wsEventToJson(evt) {
  let jsonObj = {
    event: ''
  }
  try {
    jsonObj = JSON.parse(evt.data)
    console.log('success', jsonObj)
  } catch (err) {
    console.log('error', err)
    jsonObj.event = ''
  }

  return jsonObj
}
