import React from 'react';
import styles from './style.module.css'

const KaraokeWords = (props) => {
  let currentClass = styles.karaokeWords
  if (props.currentIndex < props.wordIndex) {
    currentClass = styles.karaokeWordsDone
  } else if (props.currentIndex > props.wordIndex) {
    currentClass = styles.karaokeWordsNotDone
  }

  return (
    <div className={styles.karaokeWordsContainer}>
      <div className={styles.shadow}>{props.text}</div>
      <div
        style={{ animationDuration: props.duration + 's' }}
        className={`${styles.karaokeWord} ${currentClass}`}>
        {props.text}
      </div>
    </div>
  )
}

export default KaraokeWords
